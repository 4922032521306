import React from 'react'
import "./ImageCard.scss"
import { fetchImageThumbnailUrl } from '../../utils/ImgUtils'


export default function ImageCard({ image_URL, icons=[] }) {
    const [imageUrlAuth, setImageUrlAuth] = React.useState("");
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        if (image_URL.startsWith("data:image")) {
            // This means it is not actually an URL but the image data itself
            setImageUrlAuth(image_URL);
        } else {
            fetchImageThumbnailUrl(image_URL).then((url) => {
                setImageUrlAuth(url);
            })
        }
    }, [image_URL]);

    return (
        <div className="image_card">
            { imageUrlAuth && <img className="image_card--img" 
                src={imageUrlAuth}
                onLoad={() => setIsLoaded(true)}
                style={{ display: isLoaded ? 'block' : 'none' }}
            /> }
            { icons && <div className="image_card--icon-container">
                {icons.map((icon, index) => (<img key={index} className="image_card--icon" src={icon} />))}
            </div>}
        </div>
    )
}