import React from 'react'
import "./BaleCard.scss"
import { fetchImageThumbnailUrl } from '../../utils/ImgUtils'

import alert_on_icon from '../../assets/icons/red/alert.png';

export default function BaleCard({ image_URL, selected=true, flagged=false }) {
    const [baleImg, setBaleImg] = React.useState("");
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        if (image_URL.startsWith("data:image")) {
            // This means it is not actually an URL but the image data itself
            setBaleImg(image_URL);
        } else {
            fetchImageThumbnailUrl(image_URL).then((url) => {
                setBaleImg(url);
            })
        }
    }, [image_URL]);

    return (
        <div className="bale_card">
            { baleImg && <img className={`bale_card--img ${!selected ? "deselected" : ""}`} 
                src={baleImg}
                onLoad={() => setIsLoaded(true)}
                style={{ display: isLoaded ? 'block' : 'none' }}
            /> }
            { flagged && <img className="bale_card--alert" src={alert_on_icon} /> }
        </div>
    )
}