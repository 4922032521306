import React from "react"
import "./BaleFeedMonitor.scss"

import { AuthContext } from '../../context/AuthContext';
import {collection, query, limit, orderBy, where, onSnapshot} from "firebase/firestore";
import { firestore } from "../../context/firebase";
import { format } from "date-fns";

import BaleCard from "../BaleCard/BaleCard";
import ObservationModal from "../ObservationModal/ObservationModal";

export default function BaleFeedMonitor() {
    const { currentUser } = React.useContext(AuthContext);
    const [observations, setObservations] = React.useState({});
    const [currentTime, setCurrentTime] = React.useState(new Date());
    const [activeObservation, setActiveObservation] = React.useState(null);
    const unsubscribes = React.useRef({});

    React.useEffect(() => {
        /* Subscribe to the last observations for each device */
        const NUM_OBSERVATIONS = 10;
        const observationsRef = collection(firestore, "clients", currentUser.company.company, "observations");
        for (const deviceId of Object.keys(currentUser.devices)) {
            // Unsubscribe from the previous listener
            if (unsubscribes.current[deviceId]) {
                unsubscribes.current[deviceId]();
            }

            // Subscribe to the new listener
            const observationsQuery = query(observationsRef, 
                where("device", "==", deviceId), 
                orderBy("timestamp", "desc"), 
                limit(NUM_OBSERVATIONS));

            const unsubscribeNew = onSnapshot(observationsQuery, (snapshot) => {
                const observationsData = {};
                snapshot.forEach((doc) => {
                    const data = {...doc.data(), doc_id: doc.id};
                    observationsData[doc.id] = data;
                });
                setObservations(prev => {
                    return {...prev, [deviceId]: observationsData};
                });
            });
            unsubscribes.current[deviceId] = unsubscribeNew;
        }
    }, []);

    React.useEffect(() => {
        /* Update the current time every minute and trigger a re-render */
        const interval = setInterval(() => {
          setCurrentTime(new Date());
        }, 60000);
    
        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    function scrollObservation(scrollAmount) {
        /* Change the displayed observation by scrolling through scrollAmount observations. */
        if (!activeObservation || !scrollAmount) return;

        const observationIds = Object.keys(observations[activeObservation.device]).sort();
        const currentIndex = observationIds.indexOf(activeObservation.doc_id);
        if (currentIndex === -1) return;

        const nextIndex = currentIndex + scrollAmount;
        if (nextIndex >= observationIds.length || nextIndex < 0) return;
        const nextObservationId = observationIds[nextIndex];
        setActiveObservation(observations[activeObservation.device][nextObservationId]);
    }

    return (
        <div className="bale-feed-monitor" 
            style={{minHeight: `${Math.min(Object.keys(currentUser.devices).length, 2) * 9}rem`}}
        >
            <div className="bale-feed-monitor-lanes-container">
                {currentUser.devices && Object.keys(currentUser.devices).length > 0 && Object.keys(currentUser.devices).map((deviceId) => {
                    // Don't render the lane if there are no observations
                    if (!observations[deviceId] || Object.keys(observations[deviceId]).length === 0) {
                        return null;
                    }

                    return(
                        <div className="bale-feed-monitor-lane" key={deviceId}>
                            <div className="bale-feed-monitor-lane-title">
                                {currentUser.devices[deviceId]?.display_name ?? deviceId}
                            </div>
                            <div className="bale-feed-monitor-lane-observations">
                                {Object.entries(observations[deviceId]).map(([observationId, observation]) => {
                                    const minElapsed = Math.floor((new Date() - observation.timestamp.toDate()) / 60000);
                                    return(
                                        <div className="bale-feed-monitor-bale" onClick={() => {setActiveObservation(observation)}} key={observationId}>
                                            <BaleCard image_URL={observation.image_url} />
                                            <div className="bale-feed-monitor-bale-time">
                                                {minElapsed < 60 ? `-${minElapsed}min` : format(observation.timestamp.toDate(), "HH:mm")}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            {activeObservation && <ObservationModal 
                observation={activeObservation}
                onHide={() => setActiveObservation(null)}
                scrollObservation={scrollObservation}
            />}
        </div>
    )
}