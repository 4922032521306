// This file includes firebase resources and API, 
// exported to enable authentication and use across all other scripts. 

// firebase resources
import { firebaseConfig } from "./config";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// initialize firebase and resources
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);

// connect to firebase emulator if in development mode
const USE_EMULATOR = false;
const EMULATOR_IP = '172.20.10.2'
if (USE_EMULATOR) {
    connectFirestoreEmulator(firestore, EMULATOR_IP, 8080);
    connectFunctionsEmulator(functions, EMULATOR_IP, 5001);
    // connectStorageEmulator(storage, EMULATOR_IP, 9199);
}