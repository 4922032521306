import React from "react";
import "./Reclamation.scss";
import { useTranslation } from "react-i18next";

import { Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { serverTimestamp, collection, doc, addDoc, deleteField, getDoc, updateDoc, increment } from "firebase/firestore";

import PropertyField from "../PropertyField/PropertyField";
import DeliveryScanner from '../DeliveryScanner/DeliveryScanner';
import BaleCard from '../BaleCard/BaleCard';
import ImageCard from "../ImageCard/ImageCard";
import ReclamationImageView from "../ReclamationImageView/ReclamationImageView";
import { AuthContext } from '../../context/AuthContext';
import { firestore, storage } from "../../context/firebase";
import { uploadImage, deleteImageFromStorage } from '../../utils/ImgUtils';

// Importing icons
import back_icon        from '../../assets/icons/white/back_small.png';
import alert_icon       from '../../assets/icons/white/alert.png';
import alert_icon_red   from '../../assets/icons/red/alert.png';
import close_icon       from '../../assets/icons/white/close.svg';
import notes_icon       from '../../assets/icons/white/document.png';
import bell_icon        from '../../assets/icons/white/bell.png';

export default function Reclamation({ delivery, handleReclamationFieldChange, show, onHide, showBale }) {
    const { i18n, t } = useTranslation();
    const { currentUser, deliverySettings } = React.useContext(AuthContext);
    const [showReclamationCamera, setShowReclamationCamera] = React.useState(false);
    const [showImage, setShowImage] = React.useState(null);
    const [showNotificationPopup, setShowNotificationPopup] = React.useState(false);
    const [showMessageConfirmationPopup, setShowMessageConfirmationPopup] = React.useState(false);
    const [showNoMessageConfirmationPopup, setShowNoMessageConfirmationPopup] = React.useState(false);

    async function addReclamationImage(image) {
        // Upload the image to storage and retrieve its URL
        const imageId = `${currentUser.uid}_${format(new Date(), "yyyy-MM-dd_HH-mm-ss")}`;
        const imagePath = `clients/${currentUser.company.company}/deliveries/reclamations/${delivery.id}/${imageId}.jpg`
        const image_URL = await uploadImage(image, imagePath);

        // Add the image url to the weighing slip data
        handleReclamationFieldChange({target: {name: "reclamation_images", value: {
            [imageId]: {
                image_URL: image_URL,
                timestamp: serverTimestamp(),
            }
        }}});
    }

    function scrollReclamationImage(scrollAmount) {
        /* Change the displayed image by scrolling through scrollAmount images. */
        if (!showImage || !scrollAmount) return;

        const imageIds = Object.keys(delivery?.reclamation_data?.reclamation_images).sort();
        const currentIndex = imageIds.indexOf(showImage);
        if (currentIndex === -1) return;

        const nextIndex = currentIndex + scrollAmount;
        if (nextIndex >= imageIds.length || nextIndex < 0) return;
        const nextImageId = imageIds[nextIndex];
        setShowImage(nextImageId);
    }

    function confirmReclamation() {
        /* Confirm the reclamation and submit it to the database. */
        if (!delivery?.reclamation_data?.reclamation_nr) {
            assignReclamationNr();
        }
        handleReclamationFieldChange({target: {name: "reclamation_status", value: "confirmed"}});
        setShowNotificationPopup(true);
    }

    async function assignReclamationNr() {
        const deliverySettingsRef = doc(firestore, "clients", currentUser.company.company, "client_settings", "deliveries");
        const deliverySettingsUpdated = await getDoc(deliverySettingsRef);
        let reclamationNr = 1;
        if (deliverySettingsUpdated.exists() && deliverySettingsUpdated.data().reclamations_counter) {
            reclamationNr = deliverySettingsUpdated.data().reclamations_counter + 1;
        }
        updateDoc(deliverySettingsRef, {reclamations_counter: increment(1)});
        handleReclamationFieldChange({target: {name: "reclamation_nr", value: reclamationNr}});
    }

    async function handleNotification() {
        /* Trigger an email to recipients of the notification emails for reclamations. Will be processed by firebase add-on. */
        setShowNotificationPopup(false);
        for (const email of deliverySettings.reclamation_notification_emails) {
            await addDoc(collection(firestore, "messages"), {
                type: "reclamation",
                date: serverTimestamp(),
                to: email,
                message: {
                    subject: "[UpCircle] Neue Reklamation erfasst",
                    html: `Es wurde eine neue Reklamation erfasst!<br>
                    Lieferant: <b>${deliverySettings?.supplier?.[delivery.supplier] ?? delivery.supplier}</b><br>
                    Datum: <b>${format(delivery.date, "dd.MM.yyyy HH:mm")} Uhr</b><br>
                    Erfasst von: <b>${deliverySettings?.delivery_submitted_by_user?.[delivery.delivery_submitted_by_user] ?? delivery.delivery_submitted_by_user}</b><br>
                    Klicke auf den Link, um die Reklamation direkt im UpCircle Dashboard zu öffnen:<br>
                    <a href="https://analytics.upcircle.ai/deliveries/${delivery.id}">Öffne das Upcircle Dashboard</a><br><br>
                    Mit freundlichen Grüssen<br>
                    Dein UpCircle Team`
                }
            });

            handleReclamationFieldChange({target: {name: "notification_sent", value: true}});
        }
        setShowMessageConfirmationPopup(true);
    }

    async function deleteReclamationImage(imageId) {
        /* Delete the image from storage and remove the image entry from the weighing slip data */
        setShowImage(null);
        handleReclamationFieldChange({target: {name: "reclamation_images", value: {
            [imageId]: deleteField()
        }}});
        await deleteImageFromStorage(
            delivery.reclamation_data.reclamation_images[imageId].image_URL,
            ["_400x400", "_1920x1920"]
        );
    }

    function getScanner() {
        return (
            <div className="weighing-slip-scanner-container">
                <DeliveryScanner
                    addDeliveryImage={addReclamationImage}
                    viewLastDeliveryImage={() => {
                        setShowReclamationCamera(false);
                        setShowImage(Object.keys(delivery?.reclamation_data?.reclamation_images).sort().pop());
                    }}
                    viewOverview={() => setShowReclamationCamera(false)}
                />
                <button className="back-button" onClick={() => setShowReclamationCamera(false)}>
                    <img src={back_icon}/>
                </button>
            </div>
        )
    }

    function getImageView() {
        return (
            <ReclamationImageView 
                reclamationImage = {delivery?.reclamation_data?.reclamation_images[showImage]}
                onClose = {() => setShowImage(null)}
                handleReclamationImageChange = {(e) => handleReclamationFieldChange({
                    ...e, 
                    target:{"name":"reclamation_images", "value":{ 
                        [showImage]: {[e.target.name]:e.target.value}
                    }}
                })}
                getNextImage={() => scrollReclamationImage(1)}
                getPrevImage={() => scrollReclamationImage(-1)}
                deleteImage={() => deleteReclamationImage(showImage)}
            />
        )
    }

    function getReclamationOverview() {
        return (
            <div className="reclamation-overview">
                <div className="reclamation-header">
                    <h3 className="reclamation-title">{t("reclamation")} {delivery?.reclamation_data?.reclamation_nr?.toString().padStart(4, '0')}</h3>
                    <div className="close-button" onClick={onHide}>
                        <img src={close_icon}/>
                    </div>
                </div>

                <div className="reclamation-properties">
                    <PropertyField
                        icon={alert_icon}
                        title={t("property_problems")}
                        value={delivery?.reclamation_data?.delivery_issues ?? []}
                        name="delivery_issues"
                        type="multiple-choice-select"
                        handleChange={handleReclamationFieldChange}
                        editable={true}
                        options={{
                            discrepancy_delivery_note: t("reclamation_discrepancy_delivery_note"),
                            wetness: t("reclamation_wetness"),
                            inadmissible_material: t("reclamation_inadmissible_material"),
                            unwanted_material: t("reclamation_unwanted_material"),
                            not_all_bales_marked: t("reclamation_not_all_bales_marked"),
                        }}
                    />
                </div>

                <h3 className="reclamation-bales-title">{t("reclamation_photos")}</h3>
                <div className="reclamation-bales">
                    <div className="reclamation-bales-card-wrapper">
                        <div className="reclamation-bales-card-new" onClick={() => setShowReclamationCamera(true)}>
                            <p>+</p>
                        </div>
                        {Object.entries(delivery?.reclamation_data?.reclamation_images ?? {})
                            .sort()
                            .map(([imageId, imageData]) => {
                            return (
                                <div onClick={() => setShowImage(imageId)} key={imageId}>
                                    <ImageCard key={imageId} 
                                        image_URL={imageData.image_URL}
                                        icons={imageData?.notes ? [notes_icon] : []}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <h3 className="reclamation-bales-title">{t("reclamation_linked_bales")}</h3>
                <div className="reclamation-bales">
                    <div className="reclamation-bales-card-wrapper">
                        {Object.entries(delivery.bales || {})
                            .filter(([baleId, bale]) => bale?.flagged)
                            .sort()
                            .map(([baleId, bale]) => {
                            return (
                                <div onClick={() => {showBale(baleId)}} key={baleId}>
                                    <BaleCard key={baleId} 
                                        image_URL={bale.image_URL}
                                        flagged={bale.flagged}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="reclamation-footer-buttons">
                    <button className="reclamation-confirm-button" onClick={confirmReclamation}>
                        <img src={bell_icon} className="reclamation-confirm-icon"/>
                        {t("reclamation_confirm")}
                    </button>
                </div>

                {/* Notify about Reclamation Popup Modal */}
                <Modal 
                    show={showNotificationPopup}
                    onHide={() => setShowNotificationPopup(false)}
                    backdrop = "static"
                    className = "message-modal"
                >
                    <div className="modal-box">
                        <Modal.Header>
                            <Modal.Title>{t("reclamation_send_confirm_title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {delivery?.reclamation_data?.notification_sent && <div className="message-modal--notification-sent">
                                <img src={alert_icon_red}/>
                                <p>{t("reclamation_send_warning")}</p>
                            </div>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {setShowNotificationPopup(false); setShowNoMessageConfirmationPopup(true);}}>
                                {t("general_close")}
                            </Button>
                            <Button variant="danger" onClick={handleNotification}>
                                {t("reclamation_send")}
                            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>

                {/* Confirm about sent notification Popup Modal */}
                <Modal 
                    show={showMessageConfirmationPopup}
                    onHide={() => setShowMessageConfirmationPopup(false)}
                    backdrop = "static"
                    className = "message-modal"
                >
                    <div className="modal-box">
                        <Modal.Header>
                            <Modal.Title>{t("reclamation_send_confirmation")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button variant="danger" 
                                onClick={() => {setShowMessageConfirmationPopup(false); onHide();}}>
                                {t("general_okay")}
                            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>

                {/* Confirm about closing without opening message */}
                <Modal 
                    show={showNoMessageConfirmationPopup}
                    onHide={() => setShowNoMessageConfirmationPopup(false)}
                    backdrop = "static"
                    className = "message-modal"
                >
                    <div className="modal-box">
                        <Modal.Header>
                            <Modal.Title>{t("reclamation_not_sent_confirmation")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button variant="danger" 
                                onClick={() => {setShowNoMessageConfirmationPopup(false); onHide();}}>
                                {t("general_okay")}
                            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        )
    }    

    return (
        <Modal 
            show={show}
            onHide={onHide}
            backdrop = "static"
            className = "modal"
        >
            <div className={`modal-box reclamation-modal-box ${showReclamationCamera ? "scanner" : showImage ? "image-view" : "overview"}`}>
                {
                showReclamationCamera ? getScanner() : 
                showImage ? getImageView() : 
                getReclamationOverview()
                }
            </div>
        </Modal>
    )
}