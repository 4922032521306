import React from "react"
import "./ObservationModal.scss"

import { Modal } from 'react-bootstrap';

import { fetchImageUrl } from "../../utils/ImgUtils";
import LoadingIcon from "../LoadingIcon/LoadingIcon";

import close_icon   from '../../assets/icons/white/close.svg';
import back_icon    from '../../assets/icons/white/back_small.png';

export default function ObservationModal({ observation, onHide, scrollObservation }) {
    const [image, setImage] = React.useState(null);

    React.useEffect(() => {
        setImage(null);
        fetchImageUrl(observation.image_url).then((url) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                setImage(img);
            }
        });
    }, [observation?.image_url]);

    return(
        <Modal
            show={true}
            onHide={onHide}
            backdrop = "static"
            className = "modal">
            <div className="modal-box observation-modal"
            >
                {image && <img className="observation-image" src={image?.src} />}
                <div className="close-button" onClick={onHide}>
                    <img src={close_icon} />
                </div>

                {/* Loading spinner */}
                {!image && <LoadingIcon/>}

                {/* Buttons to jump to previous/next observation */}
                <button className="observation-swipe-button prev" onClick={() => scrollObservation(1)}>
                    <img src={back_icon}/> 
                </button>
                <button className="observation-swipe-button next" onClick={() => scrollObservation(-1)}>
                    <img src={back_icon}/> 
                </button>
            </div>
        </Modal>
    )
}