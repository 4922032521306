import React from "react";
import "./MaterialDropdown.scss";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../context/AuthContext";
import PropertyField from "../PropertyField/PropertyField";

import material_icon from "../../assets/icons/white/piechart.png"

export default function MaterialDropdown({ value, handleChange, splitProductGroup=true }) {
    const { i18n, t } = useTranslation();
    const { labelsets } = React.useContext(AuthContext);

    function handleBaseLabelChange(e) {
        const { name, value } = e.target;
        handleChange({...e, target:{"name":"base_labels", "value":{[name]:value}}});
    }

    if (splitProductGroup) {
        /* Split product group and material into two dropdowns */
        const productGroupOptions = {};
        productGroupOptions[value] = labelsets.materials.labels[value].product_group;
        for (const [id, material] of Object.entries(labelsets.materials.labels)) {
            if (Object.values(productGroupOptions).includes(material.product_group)) continue;
            productGroupOptions[id] = material.product_group;
        }

        const materialOptions = {};
        for (const [id, material] of Object.entries(labelsets.materials.labels)) {
            if (material.product_group == labelsets.materials.labels[value].product_group) {
                materialOptions[id] = material.display_name;
            }
        }

        return (
            <div className="material-selector" 
                style={Object.keys(productGroupOptions).length > 1 ? 
                    {"gridColumn": "span 2", "gridTemplateColumns": "1fr 1fr"} : 
                    {"gridColumn": "span 1", "gridTemplateColumns": "1fr"}
                }
            >
                {Object.keys(productGroupOptions).length > 1 && <PropertyField 
                    icon={material_icon} 
                    title={t("property_material_group")} 
                    value={value} 
                    name="materials"
                    type="select"
                    handleChange={handleBaseLabelChange}
                    editable={true}
                    options={productGroupOptions}
                />}
                <PropertyField 
                    icon={material_icon} 
                    title={t("property_material")}
                    value={value} 
                    name="materials"
                    type="select"
                    handleChange={handleBaseLabelChange}
                    editable={true}
                    options={materialOptions}
                />
            </div>
        )
    } else {
        /* Only show a single dropdown, mixing all product-groups together */
        const materialOptions = {};
        for (const [id, m] of Object.entries(labelsets['materials']['labels'])) {
            materialOptions[id] = m.display_name;
        }

        return (
            <PropertyField
                icon={material_icon}
                title={t("property_material")}
                value={value}
                name="materials"
                type="select"
                options={materialOptions}
                handleChange={handleBaseLabelChange}
                editable={true}
            />
        )
    }
}