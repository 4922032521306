import React from "react";
import "./WeighingSlip.scss";
import { useTranslation } from "react-i18next";

import { Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { ref } from "firebase/storage";

import PropertyField from "../PropertyField/PropertyField";
import { fetchImageThumbnailUrl, fetchImageUrl, loadImageData } from '../../utils/ImgUtils';
import DeliveryScanner from '../DeliveryScanner/DeliveryScanner';
import { AuthContext } from '../../context/AuthContext';
import { firestore, storage } from "../../context/firebase";
import { uploadImage } from '../../utils/ImgUtils';

// Importing icons
import back_icon from '../../assets/icons/white/back_small.png';
import supplier_icon from '../../assets/icons/white/delivery.png';
import notes_icon from '../../assets/icons/white/document.png';
import license_plate_icon from '../../assets/icons/white/license_plate.png';
import label_icon from '../../assets/icons/white/label.png';
import weight_icon from '../../assets/icons/white/weight.png';
import magic_wand_icon from '../../assets/icons/white/magic_wand.png';

export default function WeighingSlip({ deliveryId, weighingSlipData, show, onHide, handleWeighingSlipFieldChange }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [weighingSlipImageThumb, setWeighingSlipImageThumb] = React.useState(null);
    const [weighingSlipImage, setWeighingSlipImage] = React.useState(null);
    const [showWeighingSlipScanner, setShowWeighingSlipScanner] = React.useState(false);
    const [showFoto, setShowFoto] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [processingError, setProcessingError] = React.useState(false);

    React.useEffect(() => {
        async function loadThumbnailImage() {
            const url = await fetchImageThumbnailUrl(weighingSlipData.image.image_url);
            const img = await loadImageData(url);
            setWeighingSlipImageThumb(img.src);
        }

        if (weighingSlipData?.image?.image_url) {
            loadThumbnailImage();
        }
    }, [weighingSlipData?.image?.image_url]);

    React.useEffect(() => {
        async function loadFullResImage() {
            const url = await fetchImageUrl(weighingSlipData.image.image_url);
            const img = await loadImageData(url);
            setWeighingSlipImage(img.src);
        }

        if (showFoto && !weighingSlipImage && weighingSlipData?.image?.image_url) {
            loadFullResImage();
        }
    }, [showFoto]);

    React.useEffect(() => {
        if (weighingSlipData?.processing_status === "completed") {
            setProcessing(false);
            setProcessingError(false);
        } else if (weighingSlipData?.processing_status === "error") {
            setProcessing(false);
            setProcessingError(true);
        }
    }, [weighingSlipData]);

    async function addWeighingSlipImage(image) {
        setProcessing(true);

        // Upload the image to storage and retrieve its URL
        const imagePath = `clients/${currentUser.company.company}/deliveries/weighing_slips/${format(new Date(), "yyyy-MM-dd_HH-mm-ss")}.jpg`
        const image_URL = await uploadImage(image, imagePath);
        const storageRef = ref(storage, imagePath);

        // Create a new document for the weighing slip and its Gemini prompt
        setDoc(
            doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "weighing_slips", `${currentUser.company.company}_${format(new Date(), "yyyy-MM-dd_HH-mm-ss")}`), 
            {
                image_url: image_URL,
                image_firestore_path: `gs://${storageRef.bucket}/${storageRef.fullPath}`,
                timestamp: serverTimestamp(),
                processing_status: "pending",
                delivery_id: deliveryId,
            }
        );

        // Add the image url to the weighing slip data
        handleWeighingSlipFieldChange({target: {name: "image", value: {
            image_url: image_URL,
            timestamp: serverTimestamp(),
        }}});
    }

    function getScanner() {
        return (
            <div className="weighing-slip-scanner-container">
                <DeliveryScanner
                    addDeliveryImage={addWeighingSlipImage}
                    viewLastDeliveryImage={() => setShowWeighingSlipScanner(false)}
                    viewOverview={() => setShowWeighingSlipScanner(false)}
                    showImageAfterCapture={true}
                />
                <button className="back-button" onClick={() => setShowWeighingSlipScanner(false)}>
                    <img src={back_icon}/>
                </button>
            </div>
        )
    }

    function getImageView() {
        return (
            <div className="weighing-slip-image-view">
                <img className="weighing-slip-image" src={weighingSlipImage ?? weighingSlipImageThumb}/>
                <button className="back-button" onClick={() => setShowFoto(false)}>
                    <img src={back_icon}/>
                </button>
            </div>
        )
    }

    function getWeighingSlip() {
        return (
            <div className="weighing-slip">
                <div className="weighing-slip-image-field">
                    {weighingSlipImageThumb && !processing ? 
                        <img className="weighing-slip-image" src={weighingSlipImageThumb} onClick={() => setShowFoto(true)}/> : 
                        <img className={`magic-wand-icon ${processing && "processing"}`} src={magic_wand_icon}/>
                    }
                    {weighingSlipImageThumb && !processing ? <p>{t("weighing_slip_extraction_success")}</p> :
                        processing ? <p>{t("weighing_slip_extraction_running")}</p> :
                        processingError ? <p>{t("weighing_slip_extraction_error")}</p> :
                        <p>{t("weighing_slip_extraction_info")}</p>
                    }
                    {!processing && <button className="photo-button" onClick={() => setShowWeighingSlipScanner(true)}>{t("weighing_slip_take_picture")}</button>}
                </div>
                <div className="weighing-slip-properties">
                    <PropertyField 
                        icon={notes_icon} 
                        title={t("property_weighing_slip")}
                        value={weighingSlipData?.weighing_slip_nr} 
                        name={"weighing_slip_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={supplier_icon} 
                        title={t("property_supplier_no")}
                        value={weighingSlipData?.supplier_nr} 
                        name={"supplier_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={supplier_icon} 
                        title={t("property_supplier_name")}
                        value={weighingSlipData?.supplier_name} 
                        name={"supplier_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={label_icon} 
                        title={t("property_order_no")}
                        value={weighingSlipData?.order_nr} 
                        name={"order_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={license_plate_icon} 
                        title={t("property_license_plate")}
                        value={weighingSlipData?.vehicle_nr} 
                        name={"vehicle_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={weight_icon} 
                        title={t("property_weight_first")}
                        value={weighingSlipData?.weight_kg_first} 
                        name={"weight_kg_first"} 
                        type="number"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={weight_icon} 
                        title={t("property_weight_second")}
                        value={weighingSlipData?.weight_kg_second} 
                        name={"weight_kg_second"} 
                        type="number"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={weight_icon} 
                        title={t("property_weight_netto_1")}
                        value={weighingSlipData?.weight_kg_netto_1} 
                        name={"weight_kg_netto_1"} 
                        type="number"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={weight_icon} 
                        title={t("property_weight_netto_2")}
                        value={weighingSlipData?.weight_kg_netto_2} 
                        name={"weight_kg_netto_2"} 
                        type="number"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={true}
                    />
                </div>
            </div>
        )
    }    

    return (
        <Modal 
            show={show}
            onHide={onHide}
            backdrop = "static"
            className = "modal"
        >
            <div className={`modal-box weighing-slip-modal-box ${showWeighingSlipScanner ? "scanner" : showFoto ? "foto-view" : "overview"}`}>
                {
                showWeighingSlipScanner ? getScanner() : 
                showFoto ? getImageView() : 
                <>
                    {getWeighingSlip()}
                    <Modal.Footer>
                        <Button className="modal-button" variant="secondary" onClick={onHide}>
                            {t("general_close")}
                        </Button>
                    </Modal.Footer>
                </>
                }
            </div>
        </Modal>
    )
}