import React from "react";

export const CameraContext = React.createContext();

export const CameraContextProvider = ({children}) => {
    const [cameraDevices, setCameraDevices] = React.useState({});

    async function getCameraInfos(deviceId) {
        /* 
        Get camera resolution and direction. 
        To do this, a short stream needs to be opened -> This can disrupt a running stream! 
        */
        const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: deviceId } }
        });
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        track.stop();

        const cameraInfo = {
            resolution: Math.min(capabilities.width.max, capabilities.height.max),
            resolution_width: capabilities.width.max,
            resolution_height: capabilities.height.max,
            aspect_ratio: capabilities.width.max / capabilities.height.max,
            facingMode: capabilities.facingMode,
        }
        return cameraInfo;
    }

    async function getDevices() {
        /*
        Get all available camera devices and load their infos
        Only pick cameras facing the back of the device
        */
        const devices = await navigator.mediaDevices.enumerateDevices();
        let filteredDevices = devices.filter(device => device.kind === 'videoinput');

        let cameraInfosNew = {};
        for (const device of filteredDevices) {
            cameraInfosNew[device.deviceId] = {label: device.label, ...await getCameraInfos(device.deviceId)};
        }

        // Only keep cameras facing the back of the device (unless there are none)
        const cameraInfosFilteredBack = Object.entries(cameraInfosNew).reduce((cameraInfo, [key, value]) => {
            if (value.facingMode.indexOf("environment") > -1) {
                cameraInfo[key] = value;
            }
            return cameraInfo;
        }, {});
        if (Object.keys(cameraInfosFilteredBack).length > 0) {
            cameraInfosNew = cameraInfosFilteredBack;
        }
        
        setCameraDevices(cameraInfosNew);
        return cameraInfosNew;
    }

    React.useEffect(() => {
        getDevices()
    }, []);

    return (
        <CameraContext.Provider value={{cameraDevices}}>
            {children}
        </CameraContext.Provider>
    )
};