import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { storage } from "../context/firebase";

export async function fetchImageUrl(firebase_url) {
    const baseUrl = firebase_url.split("?")[0];
    const imageRef = ref(storage, baseUrl);
    const url = getDownloadURL(imageRef).catch((error) => {
        return "";
    })
    return url;
}

function getThumbnailUrlFromFullUrl(firebase_url, thumb_extension = "_400x400") {
    /* Get the thumbnail URL for the given firebase_url */
    const baseUrl = firebase_url.split("?")[0];
    const fileExtension =
    baseUrl.endsWith(".jpeg") ? ".jpeg" :
    baseUrl.endsWith(".jpg") ? ".jpg" :
    baseUrl.endsWith(".png") ? ".png" : ""

    return baseUrl.replace(fileExtension, "") + thumb_extension + fileExtension;
}

export async function fetchImageThumbnailUrl(firebase_url) {
    const thumbnailUrl = getThumbnailUrlFromFullUrl(firebase_url);
    const thumbImageRef = ref(storage, thumbnailUrl);
    const url = getDownloadURL(thumbImageRef).catch((error) => {
        return fetchImageUrl(firebase_url);
    })
    return url;
}

function base64ToBlob(base64) {
    /** Convert base64 image to blob */
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
};

export async function uploadImage(image, storagePath) {
    /* Upload an image to storage to the given storagePath (including filename) */
    return new Promise(async (resolve, reject) => {
        const imageDataBlob = base64ToBlob(image);
        const storageRef = ref(storage, storagePath); 
        await uploadBytesResumable(storageRef, imageDataBlob)
        const downloadURL = await getDownloadURL(storageRef)
        resolve(downloadURL);
    });
}

export async function loadImageData(imageUrl) {
    /* Fetch image data from the given imageUrl */
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            resolve(img);
        }
        img.onerror = (error) => {
            reject(new Error(`Failed to load image at ${imageUrl}: ${error.message}`));
        };
    });
}

export async function deleteImageFromStorage(firebase_url, thumb_extensions = ["_400x400"]) {
    /* Delete an image (and associated thumbnail) from storage */
    const baseUrl = firebase_url.split("?")[0];
    const imageRef = ref(storage, baseUrl);
    deleteObject(imageRef).catch((error) => {
        console.error(`Failed to delete image for ${firebase_url}: ${error.message}`);
    });

    // Delete thumbnails
    if (!thumb_extensions) return;
    for (const thumb_extension of thumb_extensions) {
        const thumbnailUrl = getThumbnailUrlFromFullUrl(firebase_url, thumb_extension);
        const thumbImageRef = ref(storage, thumbnailUrl);
        deleteObject(thumbImageRef).catch((error) => {
            console.error(`Failed to delete thumbnail for ${firebase_url}: ${error.message}`);
        });
    }
}