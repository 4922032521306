import { createContext, useEffect, useState } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { firestore } from "./firebase";
import { arrayUnion, doc, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { fetchUserInfo, fetchLabelsets, fetchDeliverySettings } from "../utils/FirebaseUtils";
import { useTranslation } from "react-i18next";

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const { i18n, t } = useTranslation();
    const [authUser, setAuthUser] = useState({});
    const [userLoaded, setUserLoaded] = useState(false);
    const [currentUser, setCurrentUser] = useState({
        uid: null,
        info:{languageCode:"de"}, 
        company:{company_displayname:"", company:"", shift_time:0},
        devices: {},
        device_id: null,
        filter_observation_classes: [],
    });
    const [labelsets, setLabelsets] = useState({});
    const [deliverySettings, setDeliverySettings] = useState({});

    async function addTimestampToUser(user) {
        updateDoc(doc(firestore, 'users', user), {last_login: serverTimestamp()});
        setDoc(doc(firestore, 'tests', 'user_login_timestamps'), {
        [user]: arrayUnion(new Date().toISOString()) // Using Firestore's server timestamp and arrayUnion
        }, { merge: true });
    }

    async function getUserData(user) {
        const currentUserNew = await fetchUserInfo(user.uid);
        currentUserNew['uid'] = user.uid;

        if (currentUserNew.company) {
            await getLabelsets(currentUserNew.company.company);
            await getDeliverySettings(currentUserNew.company.company);
        }
        setCurrentUser(currentUserNew);
        setUserLoaded(true);
        if (currentUserNew.info.languageCode) {
            i18n.changeLanguage(currentUserNew.info.languageCode);
        }
    }

    async function getLabelsets(company) {
        if (!company) return;

        const labelsetsNew = await fetchLabelsets(company);        
        setLabelsets(labelsetsNew);
    }

    async function getDeliverySettings(company) {
        if (!company) return;

        const deliverySettings = await fetchDeliverySettings(company);
        setDeliverySettings(deliverySettings);
    }

    useEffect(()=>{
        onAuthStateChanged(auth, (user)=>{
            setAuthUser(user);
            setUserLoaded(false);
            if (user) {
                getUserData(user);
                addTimestampToUser(user.uid)
            } else {
                setCurrentUser({});
            }
        });

    }, []);

    function changeActiveDevice(device_id) {
        /* Change the active device displayed in the dashboard */
        setCurrentUser({...currentUser, device_id: device_id});
        updateDoc(doc(firestore, 'users', currentUser.uid), {selected_device_id: device_id});
    }

    return(
        // Any component wrapped into "authcontext provider" will be able to reach the current user. 
        // Import into index file to cover the whole application. 
        <AuthContext.Provider value={{authUser, currentUser, userLoaded, labelsets, deliverySettings, changeActiveDevice}}>
            {children}
        </AuthContext.Provider>
    );
};