import React from "react"
import "./Deliveries.scss"

import { useTranslation } from "react-i18next";
import { AuthContext } from '../context/AuthContext';
import {collection, query, getDocs, doc, setDoc, limit, orderBy, startAfter, where, serverTimestamp} from "firebase/firestore";
import { firestore } from "../context/firebase";
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import DeliveryCard from "../components/DeliveryCard/DeliveryCard";
import Navbar from '../components/Navbar/Navbar'
import BaleFeedMonitor from "../components/BaleFeedMonitor/BaleFeedMonitor";

import logo_icon from '../assets/logos/upcircle_logo_circle.png';

export default function Deliveries() {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets, deliverySettings } = React.useContext(AuthContext);

    const [deliveries, setDeliveries] = React.useState([]);
    const [showConveyorFeed, setShowConveyorFeed] = React.useState(true);
    const lastSnapshot = React.useRef(null);
    const allLoaded = React.useRef(false);
    const blockLoading = React.useRef(false);

    const LOAD_DOCS_BATCH_SIZE = 20;

    const navigate = useNavigate();

    React.useEffect(() => {
        getDeliveries();
    }, []);

    async function getDeliveries() {
        /** Get all the deliveries from the user's company */
        if (allLoaded.current || blockLoading.current) {
            return;
        }
        blockLoading.current = true;

        const deliveriesCollection = collection(firestore, "clients", currentUser.company.company, "deliveries");
        let deliveriesQuery = query(deliveriesCollection, where("format_version", "==", 2), orderBy("date", "desc"), limit(LOAD_DOCS_BATCH_SIZE));

        // Data pagination: Get the next documents in line after the last one
        if (lastSnapshot.current) {
            deliveriesQuery = query(deliveriesQuery, startAfter(lastSnapshot.current));
        }

        const querySnapshot = await getDocs(deliveriesQuery);
        const newDeliveries = [];
        querySnapshot.forEach((doc) => {
            lastSnapshot.current = doc;
            newDeliveries.push(doc.data());
        })
        setDeliveries(prevDeliveries => {
            return [...prevDeliveries, ...newDeliveries];
        });

        // If no more documents are available, set the allLoaded flag
        if (querySnapshot.empty || querySnapshot.size < LOAD_DOCS_BATCH_SIZE) {
            allLoaded.current = true;
        }

        blockLoading.current = false;
    }

    const handleScroll = debounce((e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 200;
        if (bottom) { 
            getDeliveries();
        }
    }, 200);

    async function createNewDelivery() {
        /** Create a new delivery */
        const deliveryId = String(Math.floor(new Date().getTime()/1000));

        const deliveries_collection = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId);
        await setDoc(deliveries_collection, {
            id: deliveryId,
            delivery_images: {},
            bales: {},
            date: serverTimestamp(),
            on_create_timestamp: serverTimestamp(),
            last_edit_timestamp: serverTimestamp(),
            notes: "",
            preview_img: "",
            supplier: "",
            base_labels: {materials: Object.keys(labelsets['materials'].labels)[0]},
            format_version: 2,
        });

        navigate(`/delivery`, {state: {delivery_id: deliveryId, mode: "delivery_overview"}});
    }

    return (
        <div className="deliveries">
            <Navbar
                showConveyorFeed={showConveyorFeed}
                setShowConveyorFeed={setShowConveyorFeed}
            />
            {showConveyorFeed && deliverySettings?.features?.bale_feed_view && <>
                <h1 className="deliveries--title">{t("deliveries_bale_feed_title")}</h1>
                <BaleFeedMonitor/>
            </>}
            <h1 className="deliveries--title">{t("deliveries_title")}</h1>
            <div className="deliveries--wrapper">
                <div className="deliveries--cards-container" onScroll={handleScroll}>
                    <div className="deliveries--cards">
                        {deliveries.map((delivery) => {
                            return <DeliveryCard key={delivery.id} delivery={delivery} />
                        })}
                        {!allLoaded.current && <div className="delivery_card">
                            <div className="delivery--img-container">
                                <img src={logo_icon} className="spinning-logo" />
                            </div>
                            <p>{t("deliveries_loading")}...</p>
                        </div>}
                    </div>
                </div>
            
                <div className="deliveries--button-wrapper">
                    <button className="deliveries--new-button" onClick={createNewDelivery}>{t("deliveries_new")}</button>
                </div>
            </div>
        </div>
    )
}