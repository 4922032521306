import React from 'react'
import './DeliveryCard.scss'
import { Link } from 'react-router-dom';
import { fetchImageThumbnailUrl } from '../../utils/ImgUtils'
import { format } from 'date-fns'
import { onSnapshot, doc } from "firebase/firestore";
import { firestore } from "../../context/firebase";

import { AuthContext } from '../../context/AuthContext';
import photoIcon from '../../assets/icons/white/image.png';
import balesIcon from '../../assets/icons/white/bales_stacked.png';
import alertIcon from '../../assets/icons/red/alert.png';

export default function DeliveryCard({ delivery }) {
    const stateObj = {delivery_id: delivery.id, mode: "delivery_overview"}
    const { currentUser, labelsets, deliverySettings } = React.useContext(AuthContext);

    const [deliveryImg, setDeliveryImg] = React.useState("");
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [deliverySettingsUpdated, setDeliverySettingsUpdated] = React.useState(deliverySettings);

    const baseMaterial = labelsets['materials']?.labels?.[delivery?.base_labels?.materials] ?? "";

    // If some property fields are not found, subscribe to live updates in case they have been recently added
    React.useEffect(() => {
        if (!deliverySettingsUpdated?.supplier?.[delivery.supplier]) {
            const unsubscribe = onSnapshot(doc(firestore, "clients", currentUser.company.company, "client_settings", "deliveries"), (doc) => {
                setDeliverySettingsUpdated(doc.data());
            });
    
            return unsubscribe;
        }
    }, []);

    // Fetch the delivery image thumbnail
    React.useEffect(() => {
        if (!delivery.preview_img) return;
        fetchImageThumbnailUrl(delivery.preview_img).then((url) => {
            setDeliveryImg(url)
        })
    }, [delivery.preview_img])

    return (
        <Link to="/delivery" state={stateObj} className="delivery_card">
            <div className="delivery--img-container">
                { deliveryImg && <img className="delivery--preview-img" 
                    src={deliveryImg}
                    onLoad={() => setIsLoaded(true)}
                    style={{ display: isLoaded ? 'block' : 'none' }}
                /> }
            </div>
            <div className="delivery--datetime">
                <p className="delivery--date">{format(delivery.date.toDate(), "dd.MM.yyyy")}</p>
                <p className="delivery--time">{format(delivery.date.toDate(), "HH:mm")}</p>
            </div>
            <div className="reclamation-icon">
                { delivery?.reclamation_data?.reclamation_status && <img src={alertIcon}/> }
            </div>
            <p className="delivery--title">{deliverySettingsUpdated?.supplier?.[delivery.supplier] ?? delivery.supplier}</p>
            <p className="delivery--material">{baseMaterial.display_name}</p>
            <div className="delivery--icons">
                <p>{Object.keys(delivery.delivery_images || {}).length}</p>
                <img src={photoIcon}/>
                <p>{Object.keys(delivery.bales || {}).length}</p>
                <img src={balesIcon}/>
            </div>
        </Link>
    )
}