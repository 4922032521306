import React, { useContext} from 'react'
import "./Navbar.scss"
import { useTranslation } from "react-i18next";

import { Link } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { getDocs, collection, doc, updateDoc } from "firebase/firestore";
import { auth, firestore } from '../../context/firebase'
import { AuthContext } from '../../context/AuthContext'

import logo_circle          from '../../assets/logos/upcircle_logo_circle.png'
import logout_icon          from '../../assets/icons/white/logout.png'
import conveyor_belt_icon   from '../../assets/icons/white/conveyor_belt.png'

// NOTE: in currentUser.<attributes>, the attributes come from the firestore authentication (NOT the cloud firestore).  

const Navbar = ({ showConveyorFeed, setShowConveyorFeed }) => {
    const { i18n, t } = useTranslation();
    const { currentUser, deliverySettings } = useContext(AuthContext);
    const [ clients, setClients ] = React.useState({});

    React.useEffect(() => {
        /* If the user is an admin, get a list of all available clients */
        async function getClients() {
            const clientsNew = {};
            const snapshots = await getDocs(collection(firestore, "clients"))
            snapshots.forEach((doc) => {
                clientsNew[doc.id] = doc.data();
            });
            setClients(clientsNew);
        }

        if (currentUser.info.user_flags && currentUser.info.user_flags.includes('admin')) {
            getClients();
        }
    }, []);

    async function handleClientChange(client) {
        /* Change the user's company (For admins only) */
        await updateDoc(doc(firestore, "users", currentUser.uid), {"company": client});
        // Refresh the page to apply changes
        window.location.reload(false); 
    }

    async function handleLanguageChange(e) {
        // Handle language change
        const selectedLanguage = e.target.value;
        i18n.changeLanguage(selectedLanguage);

        // Update the language code for the user
        const userRef = doc(firestore, 'users', currentUser.uid);
        await updateDoc(userRef, {languageCode: selectedLanguage});
    };

    return (
        <div className='navbar'>
            <Link className="logo-button" to="/">
                <img src={ logo_circle } className="logo" alt="logo" />
            </Link>
            {deliverySettings?.features?.bale_feed_view && <button className={`show-feed-button ${showConveyorFeed && "show"}`} onClick={() => {setShowConveyorFeed(prev => !prev)}}>
                <img src={conveyor_belt_icon}/>
            </button>}
            <div className="user">
                <select className="client-select-dropdown"value={i18n.language} onChange={handleLanguageChange}>
                    <option value="en">EN</option>
                    <option value="de">DE</option>
                </select>
                {currentUser.info.user_flags && currentUser.info.user_flags.includes('admin') && <>
                    <select className="client-select-dropdown" value={currentUser.company.company} onChange={(e) => {handleClientChange(e.target.value)}}>
                        { Object.keys(clients).map(id => {
                            return <option key={id} value={id}>{clients[id].company_displayname}</option>
                        }) }
                    </select>
                </>}

                <p>|</p>
                <span>{t("navbar_hello")}, {currentUser.info.displayName}</span>
                <img src={currentUser.info.photoURL} alt="" className='user_photo_img'/>
                <button onClick={() => signOut(auth)} style={{display:"none"}} id='logout_icon'/>
                <label htmlFor='logout_icon'>
                    <img src={logout_icon} alt="logout icon" className='logout_icon_img'/>
                </label>
            </div>
        </div>
    )
}

export default Navbar