import React from "react";
import "./ReclamationImageView.scss";
import { useTranslation } from "react-i18next";
import { Modal, Button } from 'react-bootstrap';

import { fetchImageUrl, loadImageData } from '../../utils/ImgUtils';
import PropertyField from "../PropertyField/PropertyField";
import LoadingIcon from "../LoadingIcon/LoadingIcon";

// Importing icons
import back_icon    from '../../assets/icons/white/back_small.png';
import notes_icon   from '../../assets/icons/white/document.png';

export default function ReclamationImageView({ reclamationImage, onClose, handleReclamationImageChange, getNextImage, getPrevImage, deleteImage }) {
    const { i18n, t } = useTranslation();
    const [image, setImage] = React.useState(null);
    const [showDeletePopup, setShowDeletePopup] = React.useState(false);

    React.useEffect(() => {
        /* Fetch the image URL and load the image */
        async function loadImage() {
            const imageUrlAuth = await fetchImageUrl(reclamationImage?.image_URL);
            const img = await loadImageData(imageUrlAuth);
            setImage(img.src);
        }

        if (reclamationImage?.image_URL) {
            setImage(null);
            loadImage();
        }
    }, [reclamationImage?.image_URL]);

    return (
        <div className="image-view">
            <img className="main-image" src={image} style={{display: image ? "block" : "none"}}/>
            <button className="back-button" onClick={onClose}>
                <img src={back_icon}/>
            </button>

            <button className="scroll-button prev" onClick={getPrevImage}>
                <img src={back_icon}/>
            </button>

            <button className="scroll-button next" onClick={getNextImage}>
                <img src={back_icon}/>
            </button>

            <button className="delete-button" onClick={() => {setShowDeletePopup(true)}}>
                {t("general_delete")}
            </button>

            <div className="loading-icon" style={{display: image ? "none" : "block"}}>
                <LoadingIcon/>
            </div>

            <div className="image-properties">
                <PropertyField 
                    icon={notes_icon} 
                    title={t("property_notes")}
                    value={ reclamationImage.notes } 
                    name="notes"
                    type="textarea"
                    handleChange={handleReclamationImageChange}
                    editable={true}
                />
            </div>

            {/* Delete Image Popup Modal */}
            <Modal 
                show={showDeletePopup}
                onHide={() => setShowDeletePopup(false)}
                backdrop = "static"
                className = "delete-modal"
            >
                <div className="modal-box">
                    <Modal.Header>
                        <Modal.Title>{t("delivery_confirm_delete_image")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button className="modal-button" variant="secondary" onClick={() => setShowDeletePopup(false)}>
                            {t("general_cancel")}
                        </Button>
                        <Button className="modal-button" variant="danger" onClick={deleteImage}>
                            {t("general_delete")}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}