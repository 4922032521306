import React from 'react'
import './Delivery.scss'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom'
import { onSnapshot, query, setDoc, deleteDoc, doc, serverTimestamp, deleteField } from "firebase/firestore";
import { firestore } from "../context/firebase";
import { format } from 'date-fns';

import DeliveryOverview from './DeliveryOverview';
import DeliveryScanner from '../components/DeliveryScanner/DeliveryScanner';
import DeliveryImageView from './DeliveryImageView';
import Bale from './Bale';
import LoadingScreen from './LoadingScreen';
import { uploadImage } from '../utils/ImgUtils';
import { AuthContext } from '../context/AuthContext';

// Importing icons
import back_icon from '../assets/icons/white/back_small.png';

export default function Delivery() {
    const { i18n, t } = useTranslation();
    const location = useLocation();
    const deliveryId = location.state.delivery_id;
    const mode = location.state.mode;

    const navigate = useNavigate();

    const { currentUser } = React.useContext(AuthContext);
    const [delivery, setDelivery] = React.useState({});
    const [activeBaleId, setActiveBaleId] = React.useState("");
    const [activeDeliveryImageId, setActiveDeliveryImageId] = React.useState("");
    const [showLoadingScreen, setShowLoadingScreen] = React.useState(false);
    const unsubscribe = React.useRef(null);

    // Subscribe to active delivery and load data
    React.useEffect(() => {
        // Unsubscribe from previous query
        if (unsubscribe.current) unsubscribe.current();

        // Subscribe to active reclamation
        const deliveryQuery = query(doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId));
        const newUnsubscribe = onSnapshot(deliveryQuery, (snapshot) => {
            if (snapshot.empty) return;
            setDelivery({
                ...snapshot.data(), 
                date: snapshot.data()?.date?.toDate()
            });
        });
        unsubscribe.current = () => newUnsubscribe();
    }, [deliveryId]);

    async function addDeliveryImage(image) {
        const deliveryImageId = `${currentUser.uid}${new Date().getTime()}`;
        const newDeliveryImage = {
            id: deliveryImageId,
            image_URL: image,
            status: "uploading",
        }
        setDelivery(prevDeliveryInfo => ({...prevDeliveryInfo, bales: {...prevDeliveryInfo.bales, [deliveryImageId]: newDeliveryImage}}));
        setActiveDeliveryImageId(deliveryImageId);

        // Upload the image to storage and retrieve its URL
        const imagePath = `clients/${currentUser.company.company}/deliveries/delivery_images/${format(new Date(), "yyyy-MM-dd")}/${new Date().getTime()}.jpg`
        const image_URL = await uploadImage(image, imagePath);

        // Create doc for the new delivery image
        const deliveryImageRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "delivery_images", deliveryImageId);
        setDoc(deliveryImageRef, {
            id: deliveryImageId,
            image_URL: image_URL,
            status: "uploaded",
            timestamp: serverTimestamp(),
        });

        // Now that the image is uploaded, update the image entry with the URL of the uploaded image.
        newDeliveryImage.status = "uploaded";
        newDeliveryImage.image_URL = image_URL;

        // Add the bale to the delivery
        const deliveryRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId);
        setDoc(deliveryRef, {
            delivery_images: {[deliveryImageId]: newDeliveryImage},
            preview_img: delivery.preview_img === "" ? image_URL : delivery.preview_img,
            last_edit_timestamp: serverTimestamp(),
        }, { merge: true });
    }

    function deleteDeliveryImage(deliveryImageId) {
        // Delete the delivery-image -> Firebase functions will take care of the additional cleanup and remove images + bales
        const deliveryImageRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "delivery_images", deliveryImageId);
        deleteDoc(deliveryImageRef);
        navigate(-1);
    }

    async function deleteDelivery() {
        unsubscribe.current();
        setShowLoadingScreen(true);

        /** Delete delivery from database -> Firebase function will take care of cleanup and delete associated bales & images */
        await deleteDoc(doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId));

        /** Navigate back to deliveries page */
        navigate(-1);
    }

    function handleDeliveryFieldChange(event) {
        /* Change properties of a delivery on change */
        const { name, value } = event.target;
        const deliveryRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId);
        setDoc(deliveryRef, {[name]: value, last_edit_timestamp: serverTimestamp()}, { merge: true });
    }

    function showDeliveryImage(deliveryImageId) {
        setActiveDeliveryImageId(deliveryImageId);
        navigate(`/delivery`, {state: {delivery_id: deliveryId, mode: "delivery-image-view"}});
    }

    function showBale(baleId) {
        setActiveBaleId(baleId);
        navigate(`/delivery`, {state: {delivery_id: deliveryId, mode: "bale-view"}});
    }

    function importBales(bales) {
        /* 
        Import the bales to the delivery. Should be a dictionary of bales with the baleId as key 
        Bales with selected==True will be added to the delivery, bales with selected==False will be removed from the delivery
        */
        if (!bales) return;

        // Filter out the selected bales and only keep image_URL as attribute
        const balesImport = {}
        for (const baleId of Object.keys(bales)) {
            if (bales[baleId].selected) {
                // Add the bale (with image_URL) to the delivery
                balesImport[baleId] = {image_URL: bales[baleId].image_URL};
            } else {
                // Remove the bale from the delivery (if it was imported before)
                balesImport[baleId] = deleteField();
            }
        }

        if (Object.keys(balesImport).length === 0) return;

        handleDeliveryFieldChange({target: {name: "bales", value: balesImport}});
    }

    if (showLoadingScreen || !delivery || Object.keys(delivery).length === 0){
        return <LoadingScreen />
    } else if (mode === 'scanning') {
        /* Show the bale-scanner page */
        return (
            <div className="delivery">
                <div className="delivery--header">
                    <div onClick={() => navigate(-1)} className="delivery--back">
                        <img src={back_icon} alt="Back" className="delivery--back-icon"/>
                    </div>
                    <h3 className="delivery--title">{t("delivery")} #{deliveryId}</h3>
                </div>

                <DeliveryScanner
                    addDeliveryImage={addDeliveryImage}
                    viewLastDeliveryImage={() => navigate(`/delivery`, {state: {delivery_id: deliveryId, mode: "delivery-image-view"}})}
                    viewOverview={() => navigate(-1)}
                    showImageAfterCapture={true}
                />
            </div>
        )
    } else if (mode === "delivery-image-view") {
        /* Show the delivery-image-view page */
        return (
            <DeliveryImageView 
                deliveryId={deliveryId}
                deliveryImageId={activeDeliveryImageId}
                deleteDeliveryImage={deleteDeliveryImage}
                returnToDelivery={() => navigate(-1)}
                importBales={importBales}
                deliveryImages={delivery.delivery_images}
                setActiveDeliveryImageId={setActiveDeliveryImageId}
            />
        )
            
    } else if (mode === 'bale-view') {
        /* Show the bale-view page */
        return (
            <>
                <Bale
                    baleId={activeBaleId}
                    deliveryId={deliveryId}
                    returnToDelivery={() => {navigate(-1);}}
                    bales={delivery.bales}
                    setActiveBaleId={setActiveBaleId}
                />
            </>
        )
    } else {
        /* Show the delivery-overview page */
        return (
            <DeliveryOverview 
                handleDeliveryFieldChange={handleDeliveryFieldChange}
                deleteDelivery={deleteDelivery}
                deliveryId={deliveryId}
                delivery={delivery}
                showDeliveryImage={showDeliveryImage}
                showBale={showBale}
            />
        )
    }
}