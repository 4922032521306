import React from "react";
import "./DeliveryScanner.scss"
import { useTranslation } from "react-i18next";

import { CameraContext } from "../../context/CameraContext";
import HelpModal from "../HelpModal/HelpModal";
import Webcam from 'react-webcam';

import dashboard_icon from '../../assets/icons/white/dashboard.png';

export default function DeliveryScanner({ addDeliveryImage, viewLastDeliveryImage, viewOverview, showImageAfterCapture=false }) {
    const { i18n, t } = useTranslation();
    const {cameraDevices} = React.useContext(CameraContext);
    const [showCameraFlash, setShowCameraFlash] = React.useState(false);
    const [lastCapturedImage, setLastCapturedImage] = React.useState(null);
    const [captureCounter, setCaptureCounter] = React.useState(0);
    const [videoConstraints, setVideoConstraints] = React.useState({
        width: { min: 480, ideal: 10000, max: 10000 },
        facingMode: 'environment'
    });
    const [showHelpModal, setShowHelpModal] = React.useState(false);
    const webcamRef = React.useRef(null);

    React.useEffect(() => {
        if (Object.keys(cameraDevices).length > 0) {
            setCamera(Object.keys(cameraDevices)[0]);
        }
    }, [cameraDevices]);

    function setCamera(deviceId) {
        /** Set the camera device to use */
        const newVideoConstraints = {
            width: { min: 360, ideal: 10000, max: 12000 },
            deviceId: { exact: deviceId }
        }
        setVideoConstraints(newVideoConstraints);
    }

    async function capture() {
        /** Capture camera image and add to new delivery image */
        const captured_img = webcamRef.current.getScreenshot({});

        if (!captured_img) {
            console.error("Error capturing image");
            return;
        }

        // Show a flash animation (white screen for 100ms)
        setShowCameraFlash(true);
        setTimeout(() => {
            setShowCameraFlash(false);
        }, 100);

        setLastCapturedImage(captured_img);
        setCaptureCounter(prevcaptureCounter => prevcaptureCounter + 1);
        addDeliveryImage(captured_img);

        // Show the captured image if the option is set
        if (showImageAfterCapture) {
            viewLastDeliveryImage();
        }
    }

    return (
        <div className="delivery-scanner">
            <div className='delivery-scanner--camera-container'>
                <Webcam
                    className="camera-preview"
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    screenshotQuality={0.9}
                    forceScreenshotSourceSize={true}
                />
                <div className="help" onClick={() => setShowHelpModal(true)}>?</div>
                { Object.keys(cameraDevices).length > 1 && <select
                    className="camera-selector"
                    value={videoConstraints.deviceId?.exact}
                    onChange={(e) => setCamera(e.target.value)}
                >
                    { Object.entries(cameraDevices).map(([deviceId, deviceInfo]) => (
                        <option key={deviceId} value={deviceId} onClick={() => setCamera(deviceId)}>
                            { `${deviceInfo.label}` }
                        </option>
                    ))}
                </select>}
                { showCameraFlash && <div className="camera-flash"></div> }
            </div>

            <div className='delivery-scanner--bottom-menu'>
                <button className="capture-button" onClick={capture}>
                    SCAN
                </button>
                <div className='delivery-scanner--bales-overview' onClick={viewOverview}>
                    <img src={dashboard_icon} alt="link to overview"/>
                </div>
                { lastCapturedImage && <>
                    <div className='delivery-scanner--last-delivery' onClick={viewLastDeliveryImage}>
                        <img src={lastCapturedImage} alt="link to last bale"/>
                    </div>
                    <p className='delivery-scanner--counter'>{captureCounter}</p>
                </>}
            </div>

            {/* Popup for help/tutorial */}
            <HelpModal 
                show={showHelpModal}
                onHide={() => setShowHelpModal(false)}
                videoUrl={`https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&loop=1&playlist=9xwazD5SyVg`}
                modalTitle={t("instructions_scan_truck")}
            />

            {/* <textarea className="delivery-scanner--debug" value={debugInfo} readOnly></textarea> */}
        </div>
    )

}