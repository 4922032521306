import React from "react";
import "./HelpModal.scss"
import { useTranslation } from "react-i18next";

import { Modal, Button } from 'react-bootstrap';

export default function HelpModal({ modalTitle, videoUrl, show, onHide }) {
    const { i18n, t } = useTranslation();
    const [modalSizes, setModalSizes] = React.useState({modalWidth: 0, modalHeight: 0, videoWidth: 0, videoHeight: 0});

    React.useEffect(() => {
        // Handle window resize to correctly size the information modal and video
        function handleResize() {
            const HEADER_HEIGHT = 60;
            const FOOTER_HEIGHT = 100;
            const VIDEO_ASPECT_RATIO = 16 / 9;

            let modalWidth = window.innerWidth * 0.8;
            let modalHeight = modalWidth / VIDEO_ASPECT_RATIO + HEADER_HEIGHT + FOOTER_HEIGHT;
            if (modalHeight <= window.innerHeight * 0.8) {
                setModalSizes({
                    modalWidth: modalWidth,
                    modalHeight: modalHeight,
                    videoWidth: modalWidth,
                    videoHeight: modalWidth / VIDEO_ASPECT_RATIO,
                });
            } else {
                modalHeight = window.innerHeight * 0.8;
                modalWidth = modalHeight * VIDEO_ASPECT_RATIO;
                const videoHeight = modalHeight - HEADER_HEIGHT - FOOTER_HEIGHT;
                const videoWidth = videoHeight * VIDEO_ASPECT_RATIO;
                setModalSizes({
                    modalWidth: modalWidth,
                    modalHeight: modalHeight,
                    videoWidth: videoWidth,
                    videoHeight: videoHeight,
                });
            }

        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop = "static"
            className = "modal">
            <div className="modal-box help-modal"
                style={{
                    width: modalSizes.modalWidth, 
                    height: modalSizes.modalHeight,
                    left: window.innerWidth/2 - modalSizes.modalWidth/2,
                    top: window.innerHeight/2 - modalSizes.modalHeight/2
                }}
            >
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <iframe
                    width={modalSizes.videoWidth}
                    height={modalSizes.videoHeight}
                    src={videoUrl}
                    allow="autoplay; encrypted-media"
                    frameBorder="0"
                    allowFullScreen
                />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="modal-button" variant="primary" onClick={onHide}>
                        {t("general_okay")}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    )
}