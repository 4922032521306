import React from 'react'
import './DeliveryImageView.scss'
import { useTranslation } from "react-i18next";
import { Modal, Button } from 'react-bootstrap';

import BaleCard from '../components/BaleCard/BaleCard.jsx';
import LoadingIcon from '../components/LoadingIcon/LoadingIcon.jsx';
import HelpModal from '../components/HelpModal/HelpModal.jsx';

import { useNavigate} from 'react-router-dom';
import { AuthContext } from '../context/AuthContext.js';
import { fetchImageUrl } from "../utils/ImgUtils.js"
import { firestore } from "../context/firebase";
import { onSnapshot, setDoc, doc } from "firebase/firestore";

// Importing icons
import back_icon from '../assets/icons/white/back_small.png';
import logo_icon from '../assets/logos/upcircle_logo_circle.png';

export default function DeliveryImageView({ deliveryId, deliveryImageId, returnToDelivery, deleteDeliveryImage, importBales, deliveryImages, setActiveDeliveryImageId }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [deliveryImageDoc, setDeliveryImageDoc] = React.useState(null);
    const [deliveryImageData, setDeliveryImageData] = React.useState(null);
    const [imageSize, setImageSize] = React.useState({ width: undefined, height: undefined, scale: 1 });
    const [screenSize, setScreenSize] = React.useState({ width: window.innerWidth, height: window.innerHeight });
    const [showDeletePopup, setShowDeletePopup] = React.useState(false);
    const [showConfirmImportPopup, setShowConfirmImportPopup] = React.useState(false);
    const [showHelpModal, setShowHelpModal] = React.useState(false);

    const unsubscribe = React.useRef(null);

    const navigate = useNavigate();

    React.useEffect(() => {
        // If no bale is passed, navigate back to deliveries page (happens when refreshing page)
        if (!deliveryImageId) {
            navigate("/deliveries");
        }

        // Handle window resize to correctly size image and bounding boxes
        function handleResize() {
            setScreenSize({width: window.innerWidth, height: window.innerHeight});
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    React.useEffect(() => {
        // Unsubscribe from previous query
        if (unsubscribe.current) unsubscribe.current();
        
        const deliveryImageRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "delivery_images", deliveryImageId)
        unsubscribe.current = onSnapshot(deliveryImageRef, (doc) => {
            setDeliveryImageDoc(doc.data());
        });
    }, [deliveryImageId]);

    React.useEffect(() => {
        if (!deliveryImageDoc || !deliveryImageDoc.image_URL) {
            return;
        }

        setDeliveryImageData(null);
        fetchImageUrl(deliveryImageDoc.image_URL).then((url) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                setDeliveryImageData(img);
            }
        })
    }, [deliveryImageDoc?.image_URL]);

    React.useEffect(() => {
        /* Calculate image size based on screen size and image aspect ratio */
        if (!deliveryImageData) {
            return;
        }

        const WIDTH_BORDER = 20;
        const HEIGHT_BORDER = 350;
        let scale = (screenSize.width - WIDTH_BORDER) / deliveryImageData.width;
        if (deliveryImageData.height * scale > screenSize.height - HEIGHT_BORDER) {
            scale = (screenSize.height - HEIGHT_BORDER) / deliveryImageData.height;
        }
        setImageSize({ width: deliveryImageData.width * scale, height: deliveryImageData.height * scale, scale: scale });
    }, [deliveryImageData, screenSize]);

    function toggleBaleSelection(baleId) {
        const deliveryImageRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "delivery_images", deliveryImageId);
        setDoc(deliveryImageRef, {
            detected_bales: {
                [baleId]: { selected: !deliveryImageDoc.detected_bales[baleId].selected }
            }
        }, { merge: true })
    }

    function scrollDeliveryImage(scrollAmount) {
        /* Change the displayed image by scrolling through scrollAmount images. */
        if (!deliveryImageId || !scrollAmount) return;

        const deliveryImageIds = Object.keys(deliveryImages).sort();
        const currentIndex = deliveryImageIds.indexOf(deliveryImageId);
        if (currentIndex === -1) return;

        const nextIndex = currentIndex + scrollAmount;
        if (nextIndex >= deliveryImageIds.length || nextIndex < 0) return;
        const nextDeliveryImageId = deliveryImageIds[nextIndex];
        setActiveDeliveryImageId(nextDeliveryImageId);
    }

    if (!deliveryImageDoc) {
        <div className="delivery-image-view">
            <div className="delivery-image-view--header">
                <div onClick={returnToDelivery} className="delivery-image-view--back">
                    <img src={back_icon} alt="Back" className="delivery-image-view--back-icon"/>
                </div>
            </div>
            {/* Loading spinner */}
            <LoadingIcon />
        </div>
    }

    return (
        <div className="delivery-image-view">
            <div className="delivery-image-view--header">
                <div onClick={returnToDelivery} className="delivery-image-view--back">
                    <img src={back_icon} alt="Back" className="delivery-image-view--back-icon"/>
                </div>
                <button onClick={() => setShowDeletePopup(true)} className="delivery-image-view--delete">{t("general_delete")}</button>
            </div>
            <div className="delivery-image-view--img-container"
                style={{ width: imageSize.width ?? screenSize.width*0.9, height: imageSize.height ?? screenSize.height*0.5 }}
            >
                <img className="delivery-image-view--img" 
                    src={deliveryImageData ? deliveryImageData.src : ""}
                    style={{ display: deliveryImageData ? 'block' : 'none' }}
                />
                { deliveryImageData && deliveryImageDoc?.detected_bales && Object.entries(deliveryImageDoc.detected_bales).map(([baleId, bale], index) => {
                    const [x1, y1, x2, y2, conf, label] = bale.bbox;
                    return (
                        <div key={index} className="delivery-image-view--bale-bbox" style={{
                            left: x1 * imageSize.scale + 'px',
                            top: y1 * imageSize.scale + 'px',
                            width: (x2 - x1) * imageSize.scale + 'px',
                            height: (y2 - y1) * imageSize.scale + 'px',
                            borderColor: bale?.selected ? 'green' : 'red'
                        }} 
                        onClick={() => toggleBaleSelection(baleId)} />
                    )
                })}

                {deliveryImageDoc?.detected_bales &&<div className="help" onClick={() => setShowHelpModal(true)}>?</div>}
                {!deliveryImageData && <div className="loading-icon-container"><LoadingIcon /></div>}
                
            </div>

            {/* Buttons to jump to previous/next delivery image */}
            <button className="delivery-image-swipe-button prev" onClick={() => scrollDeliveryImage(-1)}>
                <img src={back_icon}/> 
            </button>
            <button className="delivery-image-swipe-button next" onClick={() => scrollDeliveryImage(1)}>
                <img src={back_icon}/> 
            </button>


            <div className="action-footer">
                <button className="back-button" onClick={returnToDelivery}>{t("general_back")}</button>
                {deliveryImageDoc?.detected_bales && <p>{`${Object.keys(deliveryImageDoc.detected_bales).length} ${t("delivery_bales_detected")}`}</p>}
                {deliveryImageDoc?.detected_bales && <button className="import-button" onClick={() => {importBales(deliveryImageDoc?.detected_bales); setShowConfirmImportPopup(true)}}>{t("delivery_import")}</button>}
            </div>

            <div className="bale-images">
                <div className="bale-images-wrapper">
                    {deliveryImageDoc?.detected_bales && Object.entries(deliveryImageDoc.detected_bales).sort().map(([baleId, bale], index) => {
                        return (
                            <div onClick={() => {toggleBaleSelection(baleId)}} key={index}>
                                <BaleCard key={index}
                                    image_URL={bale.image_URL}
                                    selected={bale.selected}
                                />
                            </div>
                        )
                    })}
                    {!deliveryImageDoc?.detected_bales && <LoadingIcon />}
                </div>
            </div>

            {/* Popup for confirming deletion of delivery image */}
            <Modal 
                show={showDeletePopup}
                onHide={() => setShowDeletePopup(false)}
                backdrop = "static"
                className = "modal">
                <div className="modal-box delete">
                    <Modal.Header>
                        <Modal.Title>{t("delivery_confirm_delete_image")}</Modal.Title>
                    </Modal.Header>
                    {deliveryImageDoc?.detected_bales && Object.keys(deliveryImageDoc.detected_bales).length > 0 && <Modal.Body>
                        <p>{t("delivery_confirm_delete_image_info")}</p>
                    </Modal.Body>}
                    <Modal.Footer>
                        <Button className="modal-button" variant="secondary" onClick={() => setShowDeletePopup(false)}>
                            {t("general_cancel")}
                        </Button>
                        <Button className="modal-button" variant="danger" onClick={() => {
                                deleteDeliveryImage(deliveryImageId); 
                                setShowDeletePopup(false);
                            }}>
                            {t("general_delete")}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>

            {/* Popup for confirming import of bales */}
            <Modal
                show={showConfirmImportPopup}
                onHide={() => setShowConfirmImportPopup(false)}
                backdrop = "static"
                className = "modal">
                <div className="modal-box">
                    <Modal.Header>
                        <Modal.Title>{t("delivery_import_success")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button className="modal-button" variant="primary" onClick={() => {
                                returnToDelivery();
                                setShowConfirmImportPopup(false);
                            }}>
                            {t("general_okay")}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>

            {/* Popup for help/tutorial */}
            <HelpModal 
                show={showHelpModal}
                onHide={() => setShowHelpModal(false)}
                videoUrl={`https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&loop=1&playlist=9xwazD5SyVg`}
                modalTitle={t("instructions_import_bales")}
            />
        </div>
    )
}